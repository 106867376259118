import styled from 'styled-components';
import logoImage from '../../assets/images/imagotipo.png';
import { Link as LinkScroll } from 'react-scroll';
import { Link } from 'gatsby';
import { BiTrash } from 'react-icons/bi';

//Const declaration
const logoWidth = 153;
const logoHeight = 48;

export const Header = styled.header`
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 998;
`;

export const CallToActionContainer = styled(Link)`
    display: flex;
    transition: 0.3s;
    cursor: pointer;
    flex-direction: row;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    padding: 0px 10px;
    margin-right: 48px;
    height: 41px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    margin-top: -6px;
    :hover {
        background: rgba(255, 255, 255, 0.6);
    }

    @media screen and (max-width: 1335px) and (min-width: 768px) {
        padding: 10px;
    }
    @media screen and (max-width: 1167px) and (min-width: 768px) {
        margin-right: 10px;
    }
    @media screen and (max-width: 768px) {
        margin-right: 5%;
        margin-top: 0px;
    }
`;
export const CallToActionIcon = styled(BiTrash)`
    color: #95bb34;
    font-size: 21px;
    margin-right: 8px;
    opacity: ${({ disabled }) => (disabled ? 0.8 : 1)};
    @media screen and (max-width: 1366px) {
        font-size: 18px;
    }

    @media screen and (max-width: 1335px) and (min-width: 768px) {
        margin-right: 0px;
    }
    @media screen and (max-width: 450px) {
        margin-right: 0px;
    }
`;

export const CallToActionText = styled.p`
    font-family: Comfortaa;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    color: #95bb34;

    @media screen and (max-width: 1366px) {
        font-size: 14px;
    }

    @media screen and (max-width: 1335px) and (min-width: 768px) {
        display: none;
    }

    @media screen and (max-width: 450px) {
        display: none;
    }
`;

export const Nav = styled.nav`
    transition: 0.3s ease-in;

    background: ${({ transparent }) =>
        transparent ? 'linear-gradient(150deg, #00afef 0%, #a8cf45 100%);' : 'transparent'};
    height: 80px;
    display: flex;
    font-size: 1rem;
    position: fix;
    top: 0;
    z-index: 10;
    box-shadow: ${({ transparent }) => (transparent ? '0px 2px 10px rgba(0, 0, 0, 0.25);' : '0')};

    @media screen and (max-width: 400px) {
        height: 60px;
        align-items: center;
    }
`;

export const NavBarContainer = styled.div`
    display: flex;
    height: 80px;
    z-index: 1;
    width: 100%;
    align-items: center;
    justify-content: space-around;
`;

export const Logo = styled.div`
    width: ${logoWidth}px;
    height: ${logoHeight}px;
    background: url(${logoImage}) no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-right: auto;
    margin-left: 50px;
    @media screen and (max-width: 1140px) and (min-width: 768px) {
        width: ${logoWidth * 0.7}px;
        height: ${logoHeight * 0.7}px;
        margin-left: 30px;
    }

    @media screen and (max-width: 768px) {
        width: ${logoWidth}px;
        height: ${logoHeight}px;
        margin-right: auto;
        margin-left: 70px;
    }
`;

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 768px) {
        margin-top: 0.8rem;
        display: block;
        color: #fff;
        position: absolute;
        left: 16px;
        align-self: center;
        font-size: 3rem;
        cursor: pointer;
    }
`;

export const NavMenu = styled.ul`
    display: flex;
    list-style: none;
    @media screen and (min-width: 1366px) {
        margin-right: 0px;
    }

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const NavItem = styled.li`
    margin: 1.25rem;
    font-family: Comfortaa, -apple-system, Roboto, sans-serif, serif;
    color: #faf9f9;
    transition: 0.2s ease-in-out;

    @media screen and (max-width: 1024px) and (min-width: 949px) {
        margin: 1rem;
    }
    @media screen and (max-width: 949px) {
        margin: 0.3rem;
        font-size: 0.8rem;
    }
`;

export const NavLinks = styled(LinkScroll)`
    color: #fff;
    display: flex;
    text-decoration: none;
    padding-bottom: 6px;
    height: 100%;
    border-bottom: solid 2px rgba(255, 255, 255, 0);
    transition: 0.2s ease-in;

    cursor: pointer;
    &:hover {
        transition: 0.1s ease-in;
        border-bottom: solid 2px rgba(255, 255, 255, 0.4);
    }

    &.active {
        border-bottom: 2px solid #fff;
    }
`;
