import React from 'react';

import {
    DownloadContainer,
    DownloadText,
    StoresContainer,
    AppStore,
    GooglePlay,
    ContentContainer,
} from './DownloadElements';

import GoogleLogo from '../../assets/images/playStoreIcon.svg';
import AppleLogo from '../../assets/images/appStoreIcon.svg';

const index = () => {
    return (
        <DownloadContainer id="CONTACTO">
            <ContentContainer>
                <DownloadText>Descarga la App de OASIS</DownloadText>
                <StoresContainer>
                    <GooglePlay src={GoogleLogo} />
                    <AppStore src={AppleLogo} />
                </StoresContainer>
            </ContentContainer>
        </DownloadContainer>
    );
};

export default index;
