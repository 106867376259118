import React from 'react';
import { contactText } from '../../assets/data';
import {
    ContactContainer,
    Title,
    Line,
    NetworksContainer,
    LogoContainer,
    ContentContainer,
    Instagram,
    Mail,
    Twitter,
    Linkedin,
    NetworkText,
    PhotosContainer,
    Oasis,
    Expoterra,
    ContactText,
    NetworkRow,
    Link,
} from './ContactElements';
import LogoOasis from '../../assets/images/logotipo-Oasis-color.svg';
import LogoExpoterra from '../../assets/images/logotipo-expoterra-color.svg';

const index = () => {
    return (
        <ContactContainer>
            <Title>Contacto</Title>
            <Line />
            <ContentContainer>
                <NetworksContainer>
                    <NetworkRow>
                        <LogoContainer>
                            <Instagram />
                        </LogoContainer>
                        <NetworkText>
                            <Link
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.instagram.com/oasisrecircula/"
                            >
                                oasisrecircula
                            </Link>
                        </NetworkText>
                    </NetworkRow>

                    <NetworkRow>
                        <LogoContainer>
                            <Linkedin />
                        </LogoContainer>
                        <NetworkText>
                            <Link
                                rel="noreferrer"
                                target="_blank"
                                href="https://www.linkedin.com/company/oasisrecircula"
                            >
                                oasisrecircula
                            </Link>
                        </NetworkText>
                    </NetworkRow>

                    <NetworkRow>
                        <LogoContainer>
                            <Twitter />
                        </LogoContainer>
                        <NetworkText>
                            <Link
                                target="_blank"
                                rel="noreferrer"
                                href="https://twitter.com/ORecircula"
                            >
                                @ORecircula
                            </Link>
                        </NetworkText>
                    </NetworkRow>

                    <NetworkRow>
                        <LogoContainer>
                            <Mail />
                        </LogoContainer>
                        <NetworkText>
                            <Link
                                target="_blank"
                                rel="noreferrer"
                                href="mailto:info@oasisrecircula.org"
                            >
                                info@oasisrecircula.org
                            </Link>
                        </NetworkText>
                    </NetworkRow>
                </NetworksContainer>

                <PhotosContainer>
                    <Oasis src={LogoOasis} />
                    <a target="_blank" rel="noreferrer" href="https://www.expoterra.com.ar/">
                        <Expoterra src={LogoExpoterra} />
                    </a>
                </PhotosContainer>

                <ContactText>{contactText}</ContactText>
            </ContentContainer>
        </ContactContainer>
    );
};

export default index;
