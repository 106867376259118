import * as React from 'react';
import Layout from '../components/Layout';
//import { BrowserRouter as Router } from 'react-router-dom';
import Home from '../components/Home';
import About from '../components/About';
import HowItWorks from '../components/HowItWorks';
import Benefits from '../components/Benefits';
import Advantages from '../components/Advantages';
import Download from '../components/Download';
import Team from '../components/Team';
import Contact from '../components/Contact';

const IndexPage = () => {
    return (
        //<Router>
        <Layout>
            <Home />
            <About />
            <HowItWorks />
            <Benefits />
            <Advantages />
            <Team />
            <Download />
            <Contact />
        </Layout>
        //</Router>
    );
};

export default IndexPage;
