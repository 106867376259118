import React from 'react';
import { footerText } from '../../assets/data';
import { FooterContainer, FooterText, FooterBorder } from './FooterElements';

const Footer = () => {
    return (
        <>
            <FooterBorder />
            <FooterContainer>
                <FooterText>{footerText}</FooterText>
            </FooterContainer>
        </>
    );
};

export default Footer;
