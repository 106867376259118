import React, { useState } from 'react';
import NavBar from '../Navbar';
import SideBar from '../SideBar';
import '../../assets/styles/global.css';
import Footer from '../Footer';
import { LayoutWrapper } from './LayoutElements';
import { Helmet } from 'react-helmet';

export default function Layout({ children }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <LayoutWrapper>
            <Helmet>
                <title>Oasis Recircula</title>
            </Helmet>
            <SideBar toggle={toggle} isOpen={isOpen} />
            <NavBar toggle={toggle} />
            {children}
            <Footer />
        </LayoutWrapper>
    );
}
