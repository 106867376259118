import React, { useEffect } from 'react';

import {
    BenefitsContainer,
    MainText,
    Line,
    ContentContainer,
    TextContainer,
    Title,
    SubTitle,
    Card,
    Image,
    NestedContainer,
} from './BenefitsElements';

import Aos from 'aos';
import 'aos/dist/aos.css';

import Estado from '../../assets/images/beneficios-estado.png';
import Emprendedores from '../../assets/images/beneficios-emprendedores.png';
import MedioAmbiente from '../../assets/images/beneficios-medioambiente.png';
import Ciudadanos from '../../assets/images/beneficios-ciudadanos.png';

import { benefitsText as text } from '../../assets/data/index';

const Benefits = () => {
    useEffect(() => {
        Aos.init({ offset: 80, duration: 600 });
    }, []);

    return (
        <>
            <BenefitsContainer id="BENEFICIARIOS">
                <MainText>Beneficios</MainText>
                <Line />
                <ContentContainer>
                    <NestedContainer>
                        <Card isOrder={true} data-aos="fade-down-right">
                            <Image src={Ciudadanos} />
                            <TextContainer isOrder={true}>
                                <Title>{text[1].title}</Title>
                                <SubTitle>{text[1].text}</SubTitle>
                            </TextContainer>
                        </Card>
                        <Card isOrder={false} data-aos="fade-down-left">
                            <Image src={Emprendedores} />
                            <TextContainer isOrder={false}>
                                <Title>{text[2].title}</Title>
                                <SubTitle>{text[2].text}</SubTitle>
                            </TextContainer>
                        </Card>
                    </NestedContainer>

                    <NestedContainer>
                        <Card isOrder={true} data-aos="fade-up-right">
                            <Image src={MedioAmbiente} />
                            <TextContainer isOrder={true}>
                                <Title>{text[3].title}</Title>
                                <SubTitle>{text[3].text}</SubTitle>
                            </TextContainer>
                        </Card>
                        <Card isOrder={false} data-aos="fade-up-left">
                            <Image src={Estado} />
                            <TextContainer isOrder={false}>
                                <Title>{text[4].title}</Title>
                                <SubTitle>{text[4].text}</SubTitle>
                            </TextContainer>
                        </Card>
                    </NestedContainer>
                </ContentContainer>
                <Line />
            </BenefitsContainer>
        </>
    );
};

export default Benefits;
