import styled from 'styled-components';
import { FiArrowDown } from 'react-icons/fi';
import { Link as LinkScroll } from 'react-scroll';

//const declaration
const phoneHeight = 432;
const phoneWidth = 204;

export const HomeContainer = styled.div`
    background: linear-gradient(303.52deg, #a8cf45 26.23%, #00afef 77.92%);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 92vh;
    position: relative;
    z-index: 2;
    display: flex;
    margin-top: -80px;
    padding-top: 80px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        height: 900px;
        justify-content: space-around;
    }
`;

export const Arrow = styled(FiArrowDown)`
    font-size: 1.8rem;
    z-index: 4;
    align-self: center;
    color: #828282;
`;

export const ArrowContainer = styled(LinkScroll)`
    display: flex;
    height: 48px;
    width: 48px;
    border-radius: 25px;
    align-self: center;
    background: #faf9f9;
    z-index: 3;
    align-items: center;
    justify-content: center;
    margin-top: -24px;
    cursor: pointer;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

export const PhonesContainer = styled.div`
    flex: 8;
    background: transparent;
    height: 100%;
    align-items: center;
    display: flex;
    padding-right: 32px;
    justify-content: flex-end;

    @media screen and (max-width: 768px) {
        display: flex;
        margin-top: 200px;
        align-self: center;
        flex: 1;
        padding-right: 0px;
        background: transparent;
        align-items: center;
        justify-content: center;
    }
`;

export const ContentContainer = styled.div`
    flex: 7;
    display: flex;
    flex-direction: column;
    background: transparent;
    height: 100%;
    align-items: left;
    justify-content: center;
    animation: text-animation 0.4s ease-in-out forwards;

    @keyframes text-animation {
        0% {
            transform: translateY(-10%);
            opacity: 0;
        }
        100% {
            transform: translateX(0%);
            opacity: 100;
        }
    }

    @media screen and (max-width: 768px) {
        margin-top: 100px;
    }
`;

export const MainText = styled.p`
    font-family: Comfortaa;
    font-size: 48px;
    line-height: 54px;
    color: #faf9f9;
    margin-right: 40%;
    margin-bottom: 32px;

    @media screen and (max-width: 1024px) and (min-width: 768px) {
        margin-right: 15%;
    }

    @media screen and (max-width: 768px) {
        margin-left: 40px;
        margin-right: 40px;
    }
    @media screen and (max-width: 376px) {
        margin-left: 40px;
        margin-right: 20px;
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 18px;
    }
`;
export const SecondaryText = styled.p`
    font-family: Comfortaa;
    font-size: 18px;
    line-height: 20px;
    color: #faf9f9;
    margin-right: 30%;
    margin-top: 32px;

    @media screen and (max-width: 1024px) and (min-width: 768px) {
        margin-right: 15%;
    }

    @media screen and (max-width: 768px) {
        margin-left: 40px;
        margin-right: 40px;
        font-family: Comfortaa;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
    }

    @media screen and (max-width: 376px) {
        margin-left: 40px;
        margin-right: 40px;
        font-size: 16px;
        line-height: 18px;
        margin-bottom: 18px;
    }
`;

export const StoresContainer = styled.div`
    display: flex;
    transition: all 0.2 ease-in;
    @media screen and (max-width: 768px) {
        margin-left: 40px;
    }

    @media screen and (max-width: 450px) {
        margin-left: 0px;
        align-self: center;
        justify-content: center;
    }
`;
export const AppStore = styled.img`
    margin-left: 16px;

    @media screen and (max-width: 1024px) {
        height: 60px;
        margin-left: 8px;
    }

    @media screen and (max-width: 400px) {
        height: auto;
        width: 35%;
    }
`;

export const GooglePlay = styled.img`
    margin-right: 16px;

    @media screen and (max-width: 1024px) {
        height: 60px;
        margin-right: 8px;
    }

    @media screen and (max-width: 400px) {
        height: auto;
        width: 40%;
    }
`;

export const LeftPhone = styled.img`
    width: ${phoneWidth}px;
    height: ${phoneHeight}px;
    margin-right: 340px;
    position: absolute;
    transform-origin: 100% 100%;
    opacity: 0;

    animation: appear-from-left 0.3s ease-in-out forwards;
    animation-delay: 0.4s;

    @media screen and (max-width: 1024px) and (min-width: 768px) {
        width: ${phoneWidth * 0.8}px;
        height: ${phoneHeight * 0.8}px;
        margin-right: ${170 * 1.6}px;
    }

    @media screen and (max-width: 768px) {
        width: ${phoneWidth * 0.8}px;
        height: ${phoneHeight * 0.8}px;
        margin-right: 270px;
    }

    @media screen and (max-width: 435px) {
        width: ${phoneWidth * 0.6}px;
        height: ${phoneHeight * 0.6}px;
        margin-left: ${170 * 0.6}px;
    }

    @keyframes appear-from-left {
        0% {
            transform: rotate(-20deg) translateX(-100%);
            opacity: 0;
        }
        100% {
            transform: rotate(0deg) translateX(0%);
            opacity: 100;
        }
    }
`;

export const RightPhone = styled.img`
    width: ${phoneWidth}px;
    height: ${phoneHeight}px;
    position: absolute;
    opacity: 0;
    animation: appear-from-right 0.3s ease-in-out forwards;
    transform-origin: 100% 100%;
    animation-delay: 0.4s;
    @media screen and (max-width: 1024px) and (min-width: 768px) {
        width: ${phoneWidth * 0.8}px;
        height: ${phoneHeight * 0.8}px;
    }

    @keyframes appear-from-right {
        0% {
            transform: rotate(20deg) translateX(100%);
            opacity: 0;
        }
        100% {
            transform: rotate(0deg) translateX(0%);
            opacity: 100;
        }
    }

    @media screen and (max-width: 768px) {
        width: ${phoneWidth * 0.8}px;
        height: ${phoneHeight * 0.8}px;
        margin-left: 270px;
    }
    @media screen and (max-width: 435px) {
        width: ${phoneWidth * 0.6}px;
        height: ${phoneHeight * 0.6}px;
        margin-right: ${170 * 0.6}px;
    }
`;

export const CenterPhone = styled.img`
    width: ${phoneWidth}px;
    height: ${phoneHeight}px;
    margin-bottom: 72px;
    margin-right: 170px;
    position: absolute;
    z-index: 2;

    animation: change-opacity 0.3s ease-in-out forwards;

    @media screen and (max-width: 1024px) and (min-width: 768px) {
        width: ${phoneWidth * 0.8}px;
        height: ${phoneHeight * 0.8}px;
        margin-right: ${170 * 0.8}px;
    }

    @keyframes change-opacity {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 100;
        }
    }

    @media screen and (max-width: 768px) {
        width: ${phoneWidth * 0.8}px;
        height: ${phoneHeight * 0.8}px;
        margin-right: 0px;
        margin-bottom: 50px;
    }

    @media screen and (max-width: 435px) {
        width: ${phoneWidth * 0.6}px;
        height: ${phoneHeight * 0.6}px;
        margin-right: 0px;
        margin-bottom: 50px;
    }
`;
