import React, { useEffect } from 'react';

import {
    HowItWorksContainer,
    MainText,
    Line,
    TextContainer,
    FirstContainer,
    SecondaryText,
    NumberImage1,
    NumberImage2,
    NumberImage3,
    NumberImage4,
} from './HowItWorksElements';

import Aos from 'aos';
import 'aos/dist/aos.css';

import { howItWorksText } from '../../assets/data';

import number1 from '../../assets/images/funcionamiento-icono-1.png';
import number2 from '../../assets/images/funcionamiento-icono-2.png';
import number3 from '../../assets/images/funcionamiento-icono-3.png';
import number4 from '../../assets/images/funcionamiento-icono-4.png';

const HowItWorks = () => {
    useEffect(() => {
        Aos.init({ offset: 80, duration: 600 });
    }, []);

    return (
        <>
            <HowItWorksContainer id="FUNCIONAMIENTO">
                <MainText>Funcionamiento</MainText>
                <Line />
                <FirstContainer>
                        <TextContainer data-aos="fade-left" padding={0}>
                            <NumberImage1 src={number1} />
                            <SecondaryText>{howItWorksText[1]}</SecondaryText>
                        </TextContainer>
                        <TextContainer data-aos="fade-left" padding={5}>
                            <NumberImage2 src={number2} />
                            <SecondaryText>{howItWorksText[2]}</SecondaryText>
                        </TextContainer>

                        <TextContainer data-aos="fade-left" padding={10}>
                            <NumberImage3 src={number3} />
                            <SecondaryText>{howItWorksText[3]}</SecondaryText>
                        </TextContainer>

                        <TextContainer data-aos="fade-left" padding={15}>
                            <NumberImage4 src={number4} />
                            <SecondaryText>{howItWorksText[4]}</SecondaryText>
                        </TextContainer>
                </FirstContainer>
            </HowItWorksContainer>
        </>
    );
};

export default HowItWorks;
