import React from 'react';
import {
    HomeContainer,
    Arrow,
    ArrowContainer,
    PhonesContainer,
    ContentContainer,
    MainText,
    StoresContainer,
    GooglePlay,
    AppStore,
    SecondaryText,
    LeftPhone,
    RightPhone,
    CenterPhone,
} from './HomeElements';

import { homeMainText, homeSecondaryText } from '../../assets/data';

import GoogleLogo from '../../assets/images/playStoreIcon.svg';
import AppleLogo from '../../assets/images/appStoreIcon.svg';

import centerPhone from '../../assets/images/centerPhone.png';
import leftPhone from '../../assets/images/leftPhone.png';
import rightPhone from '../../assets/images/rightPhone.png';

const Home = () => {
    return (
        <>
            <HomeContainer id="INICIO">
                <PhonesContainer>
                    <LeftPhone src={leftPhone} />
                    <CenterPhone src={centerPhone} />
                    <RightPhone src={rightPhone} />
                </PhonesContainer>
                <ContentContainer>
                    <MainText>{homeMainText}</MainText>
                    <StoresContainer>
                        <GooglePlay src={GoogleLogo} />
                        <AppStore src={AppleLogo} />
                    </StoresContainer>
                    <SecondaryText>{homeSecondaryText}</SecondaryText>
                </ContentContainer>
            </HomeContainer>
            <ArrowContainer to="SOBRE_OASIS" smooth={true} duration={600} spy={true} offset={-80}>
                <Arrow />
            </ArrowContainer>
        </>
    );
};

export default Home;
