import React from 'react';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { ApolloProvider } from '@apollo/react-hooks';
import WebLayout from './src/pages/index';
import fetch from 'isomorphic-fetch';

export const wrapRootElement = ({ element }) => {
    const cache = new InMemoryCache();
    const client = new ApolloClient({
        cache,
        link: new HttpLink({
            uri: process.env.GATSBY_API_URL,
        }),
        fetch,
    });
    return (<ApolloProvider client={client}>{element}</ApolloProvider>);
};

export const wrapPageElement = ({ element, props }) => {
    const { path } = props;
    if (path === '/') return element;

    return (<WebLayout>{element}</WebLayout>);
};
