import styled from 'styled-components';
import { FaInstagram } from 'react-icons/fa';
import { TiSocialLinkedin } from 'react-icons/ti';
import { AiOutlineTwitter } from 'react-icons/ai';
import { FiAtSign } from 'react-icons/fi';

export const ContactContainer = styled.div`
    width: 100%;
    background: linear-gradient(180deg, #ffffff 48.78%, rgba(255, 255, 255, 0) 100%);
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
`;

export const Title = styled.p`
    font-family: Comfortaa;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
    color: #000;
    text-align: center;

    @media screen and (max-width: 768px) {
        font-weight: normal;
        font-size: 36px;
        line-height: 40px;
    } ;
`;

export const Line = styled.div`
    background: linear-gradient(
        to right,
        rgba(160, 160, 160, 0),
        rgba(160, 160, 160, 50),
        rgba(160, 160, 160, 75),
        rgba(160, 160, 160, 50),
        rgba(160, 160, 160, 0)
    );
    align-self: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 1px;
    margin-top: 30px;
    margin-bottom: 30px;

    @media screen and (max-width: 1024px) {
        margin-top: 15px;
        margin-bottom: 15px;
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    } ;
`;

export const NetworksContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        padding-left: 20px;
        margin-bottom: 40px;
    } ;
`;
export const NetworkRow = styled.div`
    display: flex;
    flex-direction: row;
    padding: 5px 0;
    align-items: center;
`;

export const LogoContainer = styled.div`
    height: 24px;
    width: 24px;
    border: 1.5px solid #000000;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 24px;
    margin-right: 10px;
`;

export const Instagram = styled(FaInstagram)`
    color: #000;
`;

export const Linkedin = styled(TiSocialLinkedin)`
    color: #000;
`;
export const Twitter = styled(AiOutlineTwitter)`
    color: #000;
`;
export const Mail = styled(FiAtSign)`
    color: #000;
`;
export const PhotosContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 100px;

    @media screen and (max-width: 1100px) {
        margin: 0 50px;
    }

    @media screen and (max-width: 768px) {
        margin: 0 0;
        align-self: center;
        margin-bottom: 40px;
    } ;
`;

export const Oasis = styled.img`
    height: 60px;
    margin-bottom: 24px;
`;
export const Expoterra = styled.img`
    height: 60px;
`;
export const ContactText = styled.p`
    font-family: Comfortaa;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    width: 350px;

    @media screen and (max-width: 768px) {
        padding-left: 20px;
    } ;
`;

export const NetworkText = styled.p`
    font-family: Comfortaa;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
`;

export const Link = styled.a`
    text-decoration: none;
    color: #000;

    transition: 0.1s ease-in;
    &:hover {
        opacity: 0.6;
    }
`;
