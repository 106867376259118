import React, { useState } from 'react';

import {
    TeamContainer,
    Line,
    MainText,
    ContentContainer,
    Card,
    Image,
    Name,
    Icon,
    IconContainer,
    VectorDirect,
    VectorInstagram,
    VectorLinkedin,
    CardName,
    CardText,
    CardImage,
    OpenedCard,
    NestedContainer1,
    NestedContainer2,
    CardContainer,
} from './TeamElements';
import ImageTom from '../../assets/images/equipo-tom-Color.png';
import ImageCarlos from '../../assets/images/equipo-carlos-Color.png';
import ImageGabi from '../../assets/images/equipo-gabi-Color.png';
import ImageTomCard from '../../assets/images/equipo-tom-Color-Grande.png';
import ImageCarlosCard from '../../assets/images/equipo-carlos-Color-Grande.png';
import ImageGabiCard from '../../assets/images/equipo-gabi-Color-Grande.png';

import { teamText, teamLinks } from '../../assets/data';

const Team = () => {
    const [isOpenTom, setIsOpenTom] = useState(false);
    const [isOpenGabi, setIsOpenGabi] = useState(false);
    const [isOpenCarlos, setIsOpenCarlos] = useState(false);

    const toggleTom = () => setIsOpenTom(!isOpenTom);
    const toggleGabi = () => setIsOpenGabi(!isOpenGabi);
    const toggleCarlos = () => setIsOpenCarlos(!isOpenCarlos);

    return (
        <>
            <TeamContainer id="EQUIPO">
                <OpenedCard onClick={toggleTom} isOpen={isOpenTom}>
                    <CardContainer>
                        <CardImage src={ImageTomCard} />
                        <NestedContainer1>
                            <NestedContainer2>
                                <CardName>Tomas Corellano</CardName>
                                <IconContainer>
                                    <Icon target="_blank" href={teamLinks.tom.telegram}>
                                        <VectorDirect />
                                    </Icon>
                                    <Icon target="_blank" href={teamLinks.tom.linkedin}>
                                        <VectorLinkedin />
                                    </Icon>
                                    <Icon target="_blank" href={teamLinks.tom.instagram}>
                                        <VectorInstagram />
                                    </Icon>
                                </IconContainer>
                            </NestedContainer2>
                            <CardText>{teamText.tom}</CardText>
                        </NestedContainer1>
                    </CardContainer>
                </OpenedCard>

                <OpenedCard onClick={toggleCarlos} isOpen={isOpenCarlos}>
                    <CardContainer>
                        <CardImage src={ImageCarlosCard} />
                        <NestedContainer1>
                            <NestedContainer2>
                                <CardName>Carlos Brugiafredo</CardName>
                                <IconContainer>
                                    <Icon target="_blank" href={teamLinks.carlos.telegram}>
                                        <VectorDirect />
                                    </Icon>
                                    <Icon target="_blank" href={teamLinks.carlos.linkedin}>
                                        <VectorLinkedin />
                                    </Icon>
                                    <Icon target="_blank" href={teamLinks.carlos.instagram}>
                                        <VectorInstagram />
                                    </Icon>
                                </IconContainer>
                            </NestedContainer2>
                            <CardText>{teamText.carlos}</CardText>
                        </NestedContainer1>
                    </CardContainer>
                </OpenedCard>

                <OpenedCard onClick={toggleGabi} isOpen={isOpenGabi}>
                    <CardContainer>
                        <CardImage src={ImageGabiCard} />
                        <NestedContainer1>
                            <NestedContainer2>
                                <CardName>Gabriela Battiato</CardName>
                                <IconContainer>
                                    <Icon target="_blank" href={teamLinks.gabi.telegram}>
                                        <VectorDirect />
                                    </Icon>
                                    <Icon target="_blank" href={teamLinks.gabi.linkedin}>
                                        <VectorLinkedin />
                                    </Icon>
                                    <Icon target="_blank" href={teamLinks.gabi.instagram}>
                                        <VectorInstagram />
                                    </Icon>
                                </IconContainer>
                            </NestedContainer2>
                            <CardText>{teamText.gabi}</CardText>
                        </NestedContainer1>
                    </CardContainer>
                </OpenedCard>

                <MainText>Equipo</MainText>
                <Line />
                <ContentContainer>
                    <Card>
                        <Image src={ImageTom} onClick={toggleTom} />
                        <Name>Tomas</Name>
                        <Name>Corellano</Name>
                        <IconContainer>
                            <Icon target="_blank" href={teamLinks.tom.telegram}>
                                <VectorDirect />
                            </Icon>
                            <Icon target="_blank" href={teamLinks.tom.linkedin}>
                                <VectorLinkedin />
                            </Icon>
                            <Icon target="_blank" href={teamLinks.tom.instagram}>
                                <VectorInstagram />
                            </Icon>
                        </IconContainer>
                    </Card>
                    <Card>
                        <Image src={ImageGabi} onClick={toggleGabi} />
                        <Name>Gabriela</Name>
                        <Name>Battiato</Name>
                        <IconContainer>
                            <Icon target="_blank" href={teamLinks.gabi.telegram}>
                                <VectorDirect />
                            </Icon>
                            <Icon target="_blank" href={teamLinks.gabi.linkedin}>
                                <VectorLinkedin />
                            </Icon>
                            <Icon target="_blank" href={teamLinks.gabi.instagram}>
                                <VectorInstagram />
                            </Icon>
                        </IconContainer>
                    </Card>
                    <Card>
                        <Image src={ImageCarlos} onClick={toggleCarlos} />
                        <Name>Carlos</Name>
                        <Name>Brugiafredo</Name>
                        <IconContainer>
                            <Icon target="_blank" href={teamLinks.carlos.telegram}>
                                <VectorDirect />
                            </Icon>
                            <Icon target="_blank" href={teamLinks.carlos.linkedin}>
                                <VectorLinkedin />
                            </Icon>
                            <Icon target="_blank" href={teamLinks.carlos.instagram}>
                                <VectorInstagram />
                            </Icon>
                        </IconContainer>
                    </Card>
                </ContentContainer>
                <Line />
            </TeamContainer>
        </>
    );
};

export default Team;
