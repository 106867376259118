import styled from 'styled-components';

export const HowItWorksContainer = styled.div`
    width: 100%;
    background: linear-gradient(73.54deg, #ffffff 0%, #d6d4d4 100%);
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
`;

export const MainText = styled.p`
    font-family: Comfortaa;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
    color: #000;
    text-align: center;

    @media screen and (max-width: 768px) {
        font-weight: normal;
        font-size: 36px;
        line-height: 40px;
    } ;
`;

export const SecondaryText = styled.p`
    font-family: Comfortaa;
    font-style: normal;
    font-size: 16px;
    line-height: 18px;
    color: #000;

    @media screen and (max-width: 994px) {
        font-size: 14px;
        line-height: 16px;
    } ;
`;

export const Line = styled.div`
    background: linear-gradient(
        to right,
        rgba(160, 160, 160, 0),
        rgba(160, 160, 160, 50),
        rgba(160, 160, 160, 75),
        rgba(160, 160, 160, 50),
        rgba(160, 160, 160, 0)
    );
    align-self: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 1px;
    margin-top: 30px;
    margin-bottom: 30px;

    @media screen and (max-width: 1024px) {
        margin-top: 15px;
        margin-bottom: 15px;
    }
`;

export const NumberImage1 = styled.img`
    height: 120px;
    width: auto;

    @media screen and (max-width: 768px) {
        height: 70px;
        margin-top: -14px;
        align-self: flex-start;
    } ;
`;
export const NumberImage2 = styled.img`
    height: 120px;
    width: auto;

    @media screen and (max-width: 768px) {
        height: 70px;
        margin-top: -14px;
        align-self: flex-start;
    } ;
`;
export const NumberImage3 = styled.img`
    height: 120px;
    width: auto;

    @media screen and (max-width: 768px) {
        height: 70px;
        margin-top: -14px;
        align-self: flex-start;
    } ;
`;
export const NumberImage4 = styled.img`
    height: 120px;
    width: auto;

    @media screen and (max-width: 768px) {
        height: 70px;
        margin-top: -14px;
        align-self: flex-start;
    } ;
`;

export const TextContainer = styled.div`
    background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 7.29%,
        #dedede 8.25%,
        rgba(255, 255, 255, 0) 100.01%
    );
    width: 80%;
    height: 100px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: ${({ padding }) => padding}%;

    @media screen and (max-width: 768px) {
        margin-left: 2%;
        height: auto;
        width: 90%;

        padding-top: 10px;
        padding-bottom: 10px;
    } ;
`;

export const FirstContainer = styled.div`
    width: 90%;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (max-width: 768px) {
        align-items: flex-start;
    } ;
`;

export const SecondContainer = styled.div`
    display: flex;
    align-self: flex-end;
    flex-direction: column;
`;
