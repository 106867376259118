import React, { useEffect } from 'react';

import {
    AdvantagesContainer,
    Line,
    MainText,
    ContentContainer,
    Card,
    LogoContainer,
    Logo,
    Title,
    Subtitle,
} from './AdvantagesElements';

import Aos from 'aos';
import 'aos/dist/aos.css';

import Logo1 from '../../assets/images/logoVentajas1.svg';
import Logo2 from '../../assets/images/logoVentajas2.svg';
import Logo3 from '../../assets/images/logoVentajas3.svg';

import { advantagesText } from '../../assets/data';

const Advantages = () => {
    useEffect(() => {
        Aos.init({ offset: 80, duration: 600 });
    }, []);

    return (
        <>
            <AdvantagesContainer id="VENTAJAS">
                <MainText>Ventajas Sustentables</MainText>
                <Line />
                <ContentContainer>
                    <Card data-aos="fade-up">
                        <LogoContainer>
                            <Logo src={Logo1} />
                        </LogoContainer>
                        <Title>{advantagesText[1].title}</Title>
                        <Subtitle>
                            • Generación del interés del ciudadano en la gestión de sus residuos
                            domiciliarios desde su participación efectiva.
                            <br />
                            • Acceso de los ciudadanos a información que les permitirá producir una
                            transformación de sus hábitos para disminuir su huella de carbono.
                            <br />
                            • Mejora en la salud de la población y aumento en la calidad de vida
                            debido a la disminución de la contaminación.
                            <br />• Utilización de nuevas tecnologías de una manera amigable.
                        </Subtitle>
                    </Card>
                    <Card data-aos="fade-up">
                        <LogoContainer>
                            <Logo src={Logo2} />
                        </LogoContainer>
                        <Title>{advantagesText[2].title}</Title>
                        <Subtitle>
                            • Aumento de la eficiencia en la gestión de residuos.
                            <br />
                            • Valoración de los residuos.
                            <br />
                            • Promoción de la economía circular.
                            <br />
                            • Generación de infraestructura para la promoción de empleos de calidad,
                            emprendimientos, alianzas instituciones públicas, privadas y ONGs.
                            <br />• Mejora en las condiciones de trabajo en los centros de reciclaje
                            ya que los materiales se reciben limpios, secos y separados.
                        </Subtitle>
                    </Card>
                    <Card data-aos="fade-up">
                        <LogoContainer>
                            <Logo src={Logo3} />
                        </LogoContainer>
                        <Title>{advantagesText[3].title}</Title>
                        <Subtitle>
                            • Disminución de TN/diarias depositadas en los rellenos sanitarios a
                            basurales a cielo abierto.
                            <br />
                            • Reducción de gases de efecto invernadero (GEI) a la atmosfera.
                            <br />• Descenso de la contaminación en aguas y suelos.
                        </Subtitle>
                    </Card>
                </ContentContainer>
            </AdvantagesContainer>
        </>
    );
};

export default Advantages;
