import React from 'react';
import {
    AboutContainer,
    MainText,
    Line,
    ContentContainer,
    Video,
    InformationText,
    VideoContainer,
    TextContainer,
} from './AboutElements';

import { aboutText, aboutVideoLink } from '../../assets/data';

const Home = () => {
    return (
        <>
            <AboutContainer id="SOBRE_OASIS">
                <MainText>Sobre Nosotros</MainText>
                <Line></Line>
                <ContentContainer>
                    <VideoContainer>
                        <Video src={aboutVideoLink} />
                    </VideoContainer>
                    <TextContainer>
                        <InformationText>{aboutText.p1}</InformationText>
                        <InformationText>{aboutText.p2}</InformationText>
                        <InformationText>{aboutText.p3}</InformationText>
                        <InformationText>{aboutText.p4}</InformationText>
                    </TextContainer>
                </ContentContainer>
                <Line />
            </AboutContainer>
        </>
    );
};

export default Home;
