import styled from 'styled-components';
import { AiOutlineInstagram } from 'react-icons/ai';
import { TiSocialLinkedin } from 'react-icons/ti';
import { FaTelegramPlane } from 'react-icons/fa';

export const TeamContainer = styled.div`
    width: 100%;
    background: linear-gradient(303.52deg, #a8cf45 26.23%, #00afef 77.92%);
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
`;

export const MainText = styled.p`
    font-family: Comfortaa;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
    color: #faf9f9;
    text-align: center;

    @media screen and (max-width: 768px) {
        font-weight: normal;
        font-size: 36px;
        line-height: 40px;
    } ;
`;

export const Line = styled.div`
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 50),
        rgba(255, 255, 255, 75),
        rgba(255, 255, 255, 50),
        rgba(255, 255, 255, 0)
    );
    align-self: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 1px;
    margin-top: 30px;
    margin-bottom: 30px;

    @media screen and (max-width: 1024px) {
        margin-top: 15px;
        margin-bottom: 15px;
    }
`;

export const ContentContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`;

export const Card = styled.div`
    width: 30%;
    margin: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 768px) {
        margin: 10px 0px;
    }
`;

export const Image = styled.img`
    height: 171px;
    width: 171px;
    align-self: center;
    filter: grayscale(100%);
    transition: 0.4s ease;
    margin-bottom: 20px;
    cursor: pointer;

    &:hover {
        filter: grayscale(0%);
    }
`;

export const Name = styled.p`
    font-family: Comfortaa;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    color: #faf9f9;
`;

export const IconContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const Icon = styled.a`
    align-items: center;
    justify-content: center;
    backgorund: transparent;
    border: 1.5px solid #ffffff;
    border-radius: 32px;
    height: 32px;
    width: 32px;
    display: flex;
    font-size: 20px;
    margin: 16px 5px;
    cursor: pointer;

    transition: 0.4s ease;
    &:hover {
        background: #f2c94c;
    }
`;

export const VectorInstagram = styled(AiOutlineInstagram)`
    color: #fff;
`;
export const VectorLinkedin = styled(TiSocialLinkedin)`
    color: #fff;
`;
export const VectorDirect = styled(FaTelegramPlane)`
    color: #fff;
    margin-right: 3px;
`;

export const CardImage = styled.img`
    height: 90%;
    align-self: center;
    transition: 0.4s ease;
    cursor: pointer;
    margin-left: 30px;
    margin-right: 30px;
    border-radius: 12px;

    @media screen and (max-width: 1200px) {
        height: 70%;
    }
    @media screen and (max-width: 768px) {
        height: 50%;
    }
    @media screen and (max-width: 500px) {
        width: 100%;
        height: 30%;
        object-fit: cover;
    }
`;

export const CardName = styled.p`
    font-family: Comfortaa;
    font-weight: normal;
    font-size: 34px;
    line-height: 30px;
    color: #ffffff;
    flex: 1;
`;

export const CardText = styled.p`
    font-family: Comfortaa;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;

    @media screen and (max-width: 1200px) {+.
        font-size: 16px;
        line-height: 26px;
    }
    @media screen and (max-width: 1100px) {
        font-size: 16px;
        line-height: 22px;
    }
    @media screen and (max-width: 768px) {
        font-size: 14px;
        line-height: 16px;
    }
    @media screen and (max-width: 340px) {
        font-size: 12px;
        line-height: 14px;
    } ;
`;

export const CardContainer = styled.div`
    align-items: center;
    display: flex;
    background: linear-gradient(303.52deg, #a8cf45 26.23%, #00afef 77.92%);
    width: 90%;
    height: 400px;
    margin: 20px;
    align-self: center;
    z-index: 999;
    flex-direction: row;
    border-radius: 12px;
    padding: 20px 20px;
    justify-content: center;
    border: 1px solid #ffffff;
    padding-right: 40px;

    @media screen and (max-width: 768px) {
        width: 95%;
        height: 95%;
        flex-direction: column;
        padding-right: 20px;
        justify-content: flex-start;
    } ;
`;
export const OpenedCard = styled.div`
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
    position: fixed;
    transition: opacity 0.4s ease;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    align-self: center;
    z-index: 999;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const NestedContainer1 = styled.div`
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-top: 20px;
`;
export const NestedContainer2 = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    } ;
`;
