import styled from 'styled-components';

export const BenefitsContainer = styled.div`
    width: 100%;
    background: linear-gradient(103.74deg, #0082a3 0%, #00afef 100%);
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
`;

export const MainText = styled.p`
    font-family: Comfortaa;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
    color: #faf9f9;
    text-align: center;
`;

export const Line = styled.div`
    background: linear-gradient(
        to right,
        rgba(250, 249, 249, 0),
        rgba(250, 249, 249, 75),
        rgba(250, 249, 249, 100),
        rgba(250, 249, 249, 75),
        rgba(250, 249, 249, 0)
    );
    align-self: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 1px;
    margin-top: 30px;
    margin-bottom: 30px;

    @media screen and (max-width: 1024px) {
        margin-top: 15px;
        margin-bottom: 15px;
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
`;

export const NestedContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 768px) {
        flex-direction: column;
    } ;
`;

export const Card = styled.div`
    padding: 5px;
    display: flex;
    flex-direction: ${({ isOrder }) => (isOrder ? 'row-reverse' : 'row')};
    height: auto;
    width: 40%;

    @media screen and (max-width: 1200px) {
        width: 48%;
    }
    @media screen and (max-width: 768px) {
        width: 94%;
    } ;
`;

export const Image = styled.img`
    width: 238px;
    height: 214px;
    padding: 5px;
    align-self: center;

    @media screen and (max-width: 550px) {
        width: 162px;
        height: 147px;
    }
    @media screen and (max-width: 340px) {
        width: 140px;
        height: 127px;
    } ;
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: ${({ isOrder }) => (isOrder ? 'right' : 'left')};
    margin-left: ${({ isOrder }) => (isOrder ? '0px' : '10px')};
    margin-right: ${({ isOrder }) => (isOrder ? '10px' : '0px')};
`;

export const Title = styled.p`
    font-family: Comfortaa;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 25px;
    color: #faf9f9;
    padding-bottom: 10px;
    padding-top: 10px;

    @media screen and (max-width: 550px) {
        font-size: 16px;
        line-height: 20px;
        padding-bottom: 5px;
        padding-top: 5px;
    }
    @media screen and (max-width: 340px) {
        font-size: 14px;
        line-height: 18px;
    } ;
`;
export const SubTitle = styled.p`
    font-family: Comfortaa;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #faf9f9;

    @media screen and (max-width: 550px) {
        font-size: 12px;
        line-height: 14px;
    } ;
`;
