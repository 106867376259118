import styled from 'styled-components';

export const AdvantagesContainer = styled.div`
    width: 100%;
    background: linear-gradient(180deg, #d9d6d6 0%, #ffffff 28.44%);
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
`;

export const MainText = styled.p`
    font-family: Comfortaa;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
    color: #95bb34;
    text-align: center;

    @media screen and (max-width: 768px) {
        font-weight: normal;
        font-size: 36px;
        line-height: 40px;
    } ;
`;

export const Line = styled.div`
    background: linear-gradient(
        to right,
        rgba(160, 160, 160, 0),
        rgba(160, 160, 160, 50),
        rgba(160, 160, 160, 75),
        rgba(160, 160, 160, 50),
        rgba(160, 160, 160, 0)
    );
    align-self: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 1px;
    margin-top: 30px;
    margin-bottom: 30px;

    @media screen and (max-width: 1024px) {
        margin-top: 15px;
        margin-bottom: 15px;
    }
`;

export const ContentContainer = styled.div`
    align-self: center;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    } ;
`;

export const Card = styled.div`
    background: linear-gradient(180deg, #e1e1e1 41.96%, rgba(255, 255, 255, 0) 106.23%);
    width: 316px;
    margin: 24px;
    border-radius: 8px;

    @media screen and (max-width: 1100px) {
        width: 270px;
    }
    @media screen and (max-width: 945px) {
        width: 220px;
        margin: 16px;
    }
    @media screen and (max-width: 768px) {
        width: 90%;
    } ;
`;

export const LogoContainer = styled.div`
    width: 316px;
    height: 316px;
    background: linear-gradient(160.89deg, #00afef 0%, #a8cf45 100.05%);
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    display: flex;

    @media screen and (max-width: 1100px) {
        width: 270px;
        height: 270px;
    }
    @media screen and (max-width: 945px) {
        width: 220px;
        height: 220px;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
    } ;
`;

export const Logo = styled.img`
    height: 80%;
`;

export const Title = styled.p`
    font-family: Comfortaa;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 25px;
    color: #333333;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 28px;
    padding-bottom: 10px;

    @media screen and (max-width: 768px) {
        font-size: 18px;
        line-height: 23px;
    } ;
`;

export const Subtitle = styled.p`
    font-family: Comfortaa;
    font-style: normal;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    padding-left: 10px;
    padding-right: 10px;

    @media screen and (max-width: 768px) {
        font-size: 12px;
        line-height: 20px;
    } ;
`;
