import React, { useEffect, useState } from 'react';
//import { FaBars } from 'react-icons/fa';
import { GoGrabber } from 'react-icons/go';
import { animateScroll as scroll } from 'react-scroll';
import {
    Nav,
    NavBarContainer,
    Logo,
    MobileIcon,
    NavItem,
    NavLinks,
    NavMenu,
    Header,
    CallToActionContainer,
    CallToActionText,
    CallToActionIcon,
} from './NavBarElements';
export default function NavBar({ toggle }) {
    const [transparent, setTransparent] = useState(false);

    //Checks when scrolling and changes transparent prop

    useEffect(() => {
        if (window !== 'undefined') {
            window.addEventListener('scroll', changeBackground);
        } else {
            return;
        }
    }, []);

    const changeBackground = () => {
        const { innerWidth: width, innerHeight: height } = window;
        let size = 80;
        if (width === 800 && height === 600) {
            size = 10;
        }
        window.scrollY >= size ? setTransparent(true) : setTransparent(false);
    };

    const goHome = () => scroll.scrollToTop();

    return (
        <Header>
            <Nav transparent={transparent}>
                <NavBarContainer>
                    <Logo onClick={goHome} />
                    <MobileIcon onClick={toggle}>
                        <GoGrabber />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks
                                to="INICIO"
                                smooth={true}
                                duration={500}
                                spy={true}
                                offset={-82}
                                activeClass="active"
                            >
                                Inicio
                            </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks
                                to="SOBRE_OASIS"
                                smooth={true}
                                duration={500}
                                spy={true}
                                offset={-82}
                                activeClass="active"
                            >
                                Sobre Oasis
                            </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks
                                to="FUNCIONAMIENTO"
                                smooth={true}
                                duration={500}
                                spy={true}
                                offset={-82}
                                activeClass="active"
                            >
                                Funcionamiento
                            </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks
                                to="BENEFICIARIOS"
                                smooth={true}
                                duration={500}
                                spy={true}
                                offset={-82}
                                activeClass="active"
                            >
                                Beneficiarios
                            </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks
                                to="VENTAJAS"
                                smooth={true}
                                duration={500}
                                spy={true}
                                offset={-82}
                                activeClass="active"
                            >
                                Ventajas
                            </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks
                                to="EQUIPO"
                                smooth={true}
                                duration={500}
                                spy={true}
                                offset={-82}
                                activeClass="active"
                            >
                                Equipo
                            </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks
                                to="CONTACTO"
                                smooth={true}
                                duration={500}
                                spy={true}
                                offset={-120}
                                activeClass="active"
                            >
                                Contacto
                            </NavLinks>
                        </NavItem>
                    </NavMenu>
                    <CallToActionContainer to="/form">
                        <CallToActionIcon />
                        <CallToActionText>Cargar Residuos</CallToActionText>
                    </CallToActionContainer>
                </NavBarContainer>
            </Nav>
        </Header>
    );
}
