import React from 'react';
import {
    SideBarContainer,
    Icon,
    IconContainer,
    SideBarWrapper,
    SideBarMenu,
    SideBarLink,
    Logo,
} from './SideBarElements';

function SideBar({ isOpen, toggle }) {
    return (
        <SideBarContainer isOpen={isOpen} onClick={toggle}>
            <IconContainer>
                <Icon />
            </IconContainer>
            <SideBarWrapper>
                <SideBarMenu>
                    <SideBarLink
                        onClick={toggle}
                        to="INICIO"
                        smooth={true}
                        duration={500}
                        spy={true}
                        offset={-80}
                        activeClass="active"
                    >
                        <Logo />
                    </SideBarLink>
                    <SideBarLink
                        onClick={toggle}
                        to="INICIO"
                        smooth={true}
                        duration={500}
                        spy={true}
                        offset={-80}
                        activeClass="active"
                    >
                        Inicio
                    </SideBarLink>

                    <SideBarLink
                        onClick={toggle}
                        to="SOBRE_OASIS"
                        smooth={true}
                        duration={500}
                        spy={true}
                        offset={-80}
                        activeClass="active"
                    >
                        Sobre Oasis
                    </SideBarLink>
                    <SideBarLink
                        onClick={toggle}
                        to="FUNCIONAMIENTO"
                        smooth={true}
                        duration={500}
                        spy={true}
                        offset={-80}
                        activeClass="active"
                    >
                        Funcionamiento
                    </SideBarLink>
                    <SideBarLink
                        onClick={toggle}
                        to="BENEFICIARIOS"
                        smooth={true}
                        duration={500}
                        spy={true}
                        offset={-80}
                        activeClass="active"
                    >
                        Beneficiarios
                    </SideBarLink>
                    <SideBarLink
                        onClick={toggle}
                        to="VENTAJAS"
                        smooth={true}
                        duration={500}
                        spy={true}
                        offset={-80}
                        activeClass="active"
                    >
                        Ventajas
                    </SideBarLink>
                    <SideBarLink
                        onClick={toggle}
                        to="EQUIPO"
                        smooth={true}
                        duration={500}
                        spy={true}
                        offset={-80}
                        activeClass="active"
                    >
                        Equipo
                    </SideBarLink>
                    <SideBarLink
                        onClick={toggle}
                        to="CONTACTO"
                        smooth={true}
                        duration={500}
                        spy={true}
                        offset={-80}
                        activeClass="active"
                    >
                        Contacto
                    </SideBarLink>
                </SideBarMenu>
            </SideBarWrapper>
        </SideBarContainer>
    );
}

export default SideBar;
