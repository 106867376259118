import styled from 'styled-components';
import { Link as LinkScroll } from 'react-scroll';
import { GoX } from 'react-icons/go';
import logoImage from '../../assets/images/imagotipo.png';

//Const declaration
const logoWidth = 153;
const logoHeight = 48;

export const SideBarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    height: 100%;
    width: 100%;
    background: linear-gradient(303.52deg, #a8cf45 26.23%, #00afef 77.92%);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '100' : '0')};
    left: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`;

export const Logo = styled.div`
    width: ${logoWidth}px;
    height: ${logoHeight}px;
    background: url(${logoImage}) no-repeat;
    background-size: contain;

    @media screen and (max-width: 768px) {
        width: ${logoWidth}px;
        height: ${logoHeight}px;
    }
`;

export const Icon = styled(GoX)`
    color: #fff;
`;

export const IconContainer = styled.div`
    position: absolute;
    top: 1.5rem;
    left: 20px;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;

    @media screen and (max-width: 400px) {
        top: 1.1rem;
    }
`;

export const SideBarWrapper = styled.div`
    color: #fff;
`;

export const SideBarLink = styled(LinkScroll)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    color: #fff;
    cursor: pointer;

    // &:hover {
    //     opacity: 0.5;
    //     transition: 0.2s ease-in-out;
    // }
`;

export const SideBarMenu = styled.ul`
    display: grid;
    display-template-clomuns: 1fr;
    grid-template-rows: repeat(8, 60px);
    text-align: center;

    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(8, 60px);
    }
`;
