import styled from 'styled-components';

//const declaration
const videoHeight = 262;
const videoWidth = 482;

export const AboutContainer = styled.div`
    width: 100%;
    margin-top: -24px;
    background: linear-gradient(44.7deg, #a8cf45 8.27%, #769824 73.73%);
    box-shadow: inset 0px -5.77778px 5.77778px rgba(0, 0, 0, 0.25);
    padding: 70px 0;
    padding-bottom: 46px;
`;

export const MainText = styled.p`
    font-family: Comfortaa;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
    color: #faf9f9;
    text-align: center;
`;
//
export const Line = styled.div`
    background: linear-gradient(
        to right,
        rgba(250, 249, 249, 0),
        rgba(250, 249, 249, 75),
        rgba(250, 249, 249, 100),
        rgba(250, 249, 249, 75),
        rgba(250, 249, 249, 0)
    );
    align-self: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 1px;
    margin-top: 30px;
    margin-bottom: 30px;

    @media screen and (max-width: 1024px) {
        margin-top: 15px;
        margin-bottom: 15px;
    }
`;

export const Video = styled.embed`
    width: 100%;
    height: 100%;
    @media screen and (max-width: 768px) and (min-width: 568px) {
        width: ${videoWidth * 0.9}px;
        height: ${videoHeight * 0.9}px;
        margin-top: 20px;
    }
`;

export const VideoContainer = styled.div`
    width: ${videoWidth}px;
    height: ${videoHeight}px;
    flex: 1;

    @media screen and (max-width: 1024px) and (min-width: 769px) {
        flex: 6;
        margin-top: 22px;
        align-self: flex-start;
    }
    @media screen and (max-width: 768px) and (min-width: 568px) {
        width: ${videoWidth * 0.9}px;
        height: ${videoHeight * 0.9}px;
        margin-top: 20px;
    }
    @media screen and (max-width: 567px){
        width: ${videoWidth * 0.6}px;
        
    }
`;

export const ContentContainer = styled.div`
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    flex-direction: row;
    display: flex;

    @media screen and (max-width: 1024px) and (min-width: 768px) {
        width: 90%;
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        height: 70%;
    }
`;

export const InformationText = styled.p`
    font-size: 18px;
    line-height: 20px;
    color: #faf9f9;
    margin-bottom: 20px;

    @media screen and (max-width: 1024px) {
        font-size: 16px;
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-top: 20px;
    flex: 2;

    @media screen and (max-width: 1024px) and (min-width: 768px) {
        flex: 8;
    }
    @media screen and (max-width: 436px) {
        margin-left: 0px;
        text-align: left;
        align-self: center;
    }
`;
