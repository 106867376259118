import styled from 'styled-components';

export const DownloadContainer = styled.div`
    width: 100%;
    display: flex;
    background: linear-gradient(180deg, #f2c94c 0%, #cc9a03 100%);
    padding: 56px 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: auto;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        padding: 0 24px;
        padding-left: 36px;
        align-items: center;
        justify-content: center;
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    @media screen and (max-width: 768px) {
        flex-direction: column;

        align-self: center;
        align-items: center;
        justify-content: center;
    }
`;

export const DownloadText = styled.p`
    font-family: Comfortaa;

    font-size: 34px;
    line-height: 38px;
    text-align: right;
    width: 250px;
    color: #faf9f9;

    @media screen and (max-width: 768px) {
        text-align: left;
        margin-top: 24px;
        margin-bottom: 12px;
        align-self: flex-start;
    }
`;

export const StoresContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 40px;

    @media screen and (max-width: 768px) {
        padding: 0 0;
        align-self: flex-start;
        margin-bottom: 24px;
    } ;
`;

export const AppStore = styled.img`
    margin-left: 16px;

    @media screen and (max-width: 1024px) {
        height: 60px;
    }

    @media screen and (max-width: 427px) {
        height: auto;
        width: 40%;
        margin-right: 24px;
    }
`;

export const GooglePlay = styled.img`
    margin-right: 16px;

    @media screen and (max-width: 1024px) {
        height: 60px;
    }

    @media screen and (max-width: 427px) {
        height: auto;
        width: 44%;
    }
`;
