import styled from 'styled-components';

export const FooterContainer = styled.footer`
    background: #00afef;
    display: flex;
    width: 100%;
    align-self: flex-end;
    height: 36px;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

export const FooterText = styled.p`
    font-size: 9px;
    font-weight: 400;
`;

export const FooterBorder = styled.div`
    background: #a8cf45;
    display: flex;
    width: 100%;
    height: 5px;
    align-items: center;
`;
